@import '../../default/scss/default';
@import '../assets/svg/sassvg/sassvg';

// begin:: drawer
.drawer {
     @include size(100%);
     bottom: 0;
     left: -100%;
     opacity: 0;
     position: fixed;
     top: 0;
     transition: 0.2s ease-in-out;
     z-index: 10;

     &__container {
          display: flex;
          flex-direction: row;
          height: 100%;
     }

     &__row {
          &:nth-child(1) {
               display: flex;
               justify-content: flex-end;
               margin-bottom: 30px;
          }
     }

     &__social {
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;

          li {
               @include size(25px);

               &:nth-child(1) {
                    @include sassvg('facebook', $brand-color-1);
                    @include background-properties;
                    margin-right: 10px;
               }

               &:nth-child(2) {
                    @include sassvg('instagram', $brand-color-1);
                    @include background-properties;
               }

               a {
                    @include size(100%);
                    display: block;
               }
          }
     }

     &__close {
          @include size(20px);
          @include sassvg('xmark', $brand-color-1);
          @include background-properties;
          background-color: transparent;
          border: 0;
     }

     &__left {
          background: $brand-color-white;
          min-width: 250px;
          padding: 15px 20px;
          width: 250px;

          @include from(576px) {
               min-width: 300px;
               width: 300px;
          }
     }

     &__list {
          list-style: none;
          margin: 0;
          padding: 0;
     }

     &__item {
          margin-bottom: 15px;
          pointer-events: none;

          &:last-of-type {
               margin-bottom: 30px;
          }
     }

     &__link,
     &__phone {
          color: $brand-color-black;
          pointer-events: all;
          text-decoration: none;
     }

     &__phone {
          border: 1px solid $brand-color-1;
          border-radius: 3px;
          padding: 5px 10px;
          color: $brand-color-1;
          text-transform: uppercase;
     }

     &__right {
          background: $brand-color-3;
          flex: 1;
     }

     &--active {
          left: 0;
          opacity: 1;
     }
}
// end:: drawer

// begin:: about
.about {
     scroll-margin-top: 100px;
     padding: 30px 0;

     &__title {
          @include main-title;
     }

     &__subtitle {
          font-size: 20px;
          margin-bottom: 20px;
          margin-top: 20px;
          text-align: center;

          @include from(768px) {
               font-size: 25px;
               margin-top: 0;
               text-align: left;
          }
     }

     &__description {
          font-size: 15px;
          line-height: 23px;
          margin-bottom: 20px;
          text-align: justify;

          @include from(768px) {
               font-size: 16px;
               line-height: 28px;
               text-align: left;
          }
     }

     &__link {
          border: 1px solid $brand-color-1;
          border-radius: 3px;
          color: $brand-color-1;
          display: block;
          padding: 0.6rem 2rem;
          text-decoration: none;
          transition: 0.2s;
          text-transform: uppercase;

          &:hover {
               background-color: $brand-color-1;
               color: $brand-color-white;
          }
     }

     &__container {
          @include main-container;
          display: flex;
          flex-direction: column;

          @include from(768px) {
               align-items: center;
               flex-direction: row;
          }
     }

     &__img-wrapper {
          position: relative;

          &:before {
               @include from(576px) {
                    @include size(100%);
                    position: absolute;
                    content: '';
                    bottom: -20px;
                    border: 1px solid $brand-color-1;
                    z-index: -1;
                    right: -20px;
               }
          }
     }

     &__column {
          @include flex;
          flex: 1;

          &:nth-child(1) {
               align-items: flex-start;
               flex-direction: column;
               order: 2;

               @include from(768px) {
                    order: 1;
               }
          }

          &:nth-child(2) {
               display: none;
               justify-content: flex-end;
               margin-bottom: 40px;
               order: 1;

               img {
                    max-width: 100%;
                    display: flex;
                    object-fit: cover;
               }

               @include from(1000px) {
                    display: block;
                    margin-bottom: 0;
                    order: 2;
                    padding: 3rem 0 3rem 3rem;
               }
          }
     }
}
// end:: about

// begin:: categories
.categories {
     scroll-margin-top: 100px;
     background-color: $brand-color-4;
     padding: 60px 0;

     &__title {
          @include main-title;
     }

     &__container {
          @include main-container;
     }

     &__article {
          height: 250px;

          a {
               @include size(100%);
               display: grid;
          }

          @include from(500px) {
               width: 230px;
          }

          @include from(1000px) {
               width: 310px;
          }

          @include from(1300px) {
               width: 370px;
               min-width: unset;
          }

          img {
               @include size(100%);
               object-fit: cover;
          }
     }

     &__row {
          grid-gap: 10px;
          text-align: center;

          &:not(&--filters) {
               @include flex(flex, center, center);
               flex-wrap: wrap;
          }

          &.hidden {
               display: none !important;
          }

          &--filters {
               margin-top: 60px;
               margin-bottom: 30px;
               display: grid;
               justify-content: center;
               grid-template-columns: repeat(2, 1fr);
               grid-row-gap: 10px;
               grid-column-gap: 0;

               @include from(576px) {
                    grid-template-columns: repeat(3, 150px);
               }

               @include from(768px) {
                    grid-template-columns: repeat(3, 200px);
               }

               @include from(1200px) {
                    grid-template-columns: repeat(6, 150px);
               }
          }
     }

     &__button {
          border-radius: 5px;
          margin-right: 10px;
          padding: 12px;
          background: transparent;
          border: 1px solid $brand-color-1;
          cursor: pointer;
          transition: 0.25s ease-in-out;
          text-transform: uppercase;
          color: $brand-color-1;

          &--active,
          &:hover {
               background-color: $brand-color-1;
               color: $brand-color-white;
          }
     }

     &__article {
          position: relative;
          background: $brand-color-white;
          border-radius: 5px;
          box-shadow: 0 2px 3px 3px #0000000a;
          overflow: hidden;

          &.hidden {
               display: none;
          }

          img {
               @include size(100%);
               object-fit: cover;
               border-radius: 5px;
               transition: 0.25s ease-in-out;
          }

          &:after {
               pointer-events: none;
               @include sassvg('search', $brand-color-1);
               @include size(100%);
               transition: 0.25s ease-in-out;
               position: absolute;
               top: 50%;
               left: 50%;
               transform: translate(-50%, -50%);
               border-radius: 5px;
               background-position: center;
               background-size: 40px;
               background-color: rgba(0, 0, 0, 0.67);
               content: '';
               opacity: 0.7;
          }

          @include from(1000px) {
               &:after {
                    opacity: 0;
               }

               &:hover {
                    cursor: pointer;

                    &:after {
                         transition: 0.25s ease-in-out;
                         opacity: 1;
                    }

                    img {
                         transition: 0.25s ease-in-out;
                         transform: scale(1.1) rotate(2deg);
                    }
               }
          }
     }

     &__load-more {
          display: block;
          margin: 20px auto 0;
          padding: 12px 20px;
          border-radius: 5px;
          color: $brand-color-1;
          border: 1px solid $brand-color-1;
          background-color: transparent;
          text-transform: uppercase;
          cursor: pointer;
          transition: 0.25s ease-in-out;
          font-size: 12px;

          @include from(1300px) {
               font-size: 14px;
          }

          &:hover {
               color: $brand-color-white;
               background-color: $brand-color-1;
          }
     }
}
// end:: categories

// begin:: social
.social {
     background: radial-gradient(circle, rgba(188, 147, 37, 0.4234068627450981) 0%, rgba(188, 147, 37, 0.7539390756302521) 100%),
          url('../images/social-banner.webp') no-repeat;
     background-position: -597px 0;
     background-size: cover;
     padding: 40px 0;

     &__container {
          @include main-container;
     }

     &__title {
          color: $brand-color-white;
          font-size: 16px;
          font-weight: 700;
          max-width: 200px;
          text-transform: uppercase;

          @include from(600px) {
               font-size: 18px;
               max-width: 235px;
          }
     }

     &__list {
          display: flex;
          list-style: none;
          margin: 15px 0 0;
          padding: 0;
     }

     &__link {
          @include size(35px);
          display: block;

          &--facebook {
               @include sassvg('facebook', $brand-color-white);
               margin-right: 10px;
          }

          &--instagram {
               @include sassvg('instagram', $brand-color-white);
          }
     }

     @include from(400px) {
          background-position: -500px 0;
     }

     @include from(500px) {
          background-position: -510px 0;
     }

     @include from(600px) {
          background-position: -420px 0;
     }

     @include from(768px) {
          background-position: center;
     }
}
// end:: social

// begin:: testimonials
.testimonials {
     scroll-margin-top: 100px;
     padding: 30px 0;

     &__title {
          @include main-title;
     }

     &__container {
          margin-top: 30px;

          @include from(576px) {
               @include main-container;
               margin-top: 60px;
          }
     }

     &__box {
          width: 100%;
     }

     &__bullets {
          margin-top: 50px;
          text-align: center;

          .glide__bullet {
               @include size(15px);
               border: 0;
               border-radius: 20px;
               cursor: pointer;

               &--active {
                    background-color: $brand-color-1;
               }
          }
     }

     .article {
          &__head {
               position: relative;
               text-align: center;
               z-index: 1;

               img {
                    border-radius: 100px;
                    margin-top: 9px;
                    width: 98px;
                    height: 98px;
                    object-fit: contain;
               }

               &:after {
                    @include size(110px);
                    background-color: $brand-color-white;
                    border-radius: 100px;
                    content: '';
                    display: block;
                    left: 50%;
                    position: absolute;
                    top: 0;
                    transform: translateX(-50%);
                    z-index: -1;
               }
          }

          &__body {
               background: $brand-color-5;
               border-radius: 5px;
               margin-top: -60px;
               padding: 60px 20px 20px;

               a {
                    color: $brand-color-1;
                    font-weight: 700;
                    margin-top: 20px;
               }

               @include from(600px) {
                    min-height: 390px;
               }

               @include from(1000px) {
                    min-height: 315px;
               }

               @include from(1200px) {
                    min-height: 280px;
               }
          }

          &__description {
               color: $brand-color-6;
               display: block;
               line-height: 24px;
               padding-right: 25px;
               position: relative;

               &:before {
                    @include size(20px);
                    @include sassvg('quote-left', $brand-color-1);
                    background-size: contain;
                    content: '';
                    left: 0;
                    position: absolute;
                    top: 0;
               }

               &:after {
                    @include sassvg('quote-left', $brand-color-1);
                    @include size(20px);
                    background-size: contain;
                    bottom: 0;
                    content: '';
                    position: absolute;
                    right: 0;
                    transform: rotate(180deg);
               }

               &:first-letter {
                    border-left: 2em solid transparent;
               }
          }

          &__info {
               color: $brand-color-1;
               font-weight: 700;
               margin-top: 20px;

               span {
                    color: $brand-color-6;
               }
          }
     }
}
// end:: testimonials

// begin:: services
.services {
     background-color: $brand-color-4;
     padding: 30px 0 60px 0;

     &__container {
          @include main-container;
          display: grid;
          grid-gap: 20px;

          @include from(768px) {
               grid-template-columns: repeat(2, 1fr);
          }

          @include from(1300px) {
               grid-template-columns: repeat(4, 1fr);
          }
     }

     &__title {
          @include main-title;
          margin-bottom: 50px;
     }

     &__box {
          align-items: center;
          background-color: $brand-color-white;
          border-radius: 5px;
          box-shadow: 0 8px 15px 3px $brand-color-8;
          display: flex;
          flex-direction: column;
          padding: 30px 15px;
          text-align: center;
     }

     &__icon {
          @include size(70px);
          background-color: $brand-color-1;
          border-radius: 60px;

          &--couch {
               @include sassvg('couch', $brand-color-white);
               background-position: center;
          }

          &--cube {
               @include sassvg('cube', $brand-color-white);
               background-position: center;
          }

          &--truck {
               @include sassvg('truck', $brand-color-white);
               background-position: center;
          }

          &--circle-check {
               @include sassvg('circle-check', $brand-color-white);
               background-position: center;
          }
     }

     &__box-title {
          margin-top: 20px;
          font-size: 16px;
     }
}
// end:: services

// begin:: footer
.footer {
     background-color: #242424;
     padding-top: 30px;

     * {
          color: $brand-color-white;
     }

     &__container {
          @include main-container;
     }

     &__container-inner {
          &:nth-child(1) {
               padding-bottom: 20px;

               .footer__row {
                    ul {
                         padding: 0;
                         list-style: none;
                         margin: 0;

                         &:not(&:last-child) {
                              margin-bottom: 40px;
                         }

                         &:nth-child(1) {
                              li {
                                   position: relative;

                                   &:not(&:first-child) {
                                        padding-left: 30px;

                                        &:after {
                                             @include size(20px);
                                             position: absolute;
                                             top: 50%;
                                             transform: translateY(-50%);
                                             left: 0;
                                             content: '';
                                        }
                                   }

                                   &.footer__address {
                                        &:after {
                                             @include sassvg('location', $brand-color-white);
                                             background-position: left;
                                             background-size: contain;
                                        }
                                   }

                                   &.footer__phone {
                                        &:after {
                                             @include sassvg('phone', $brand-color-white);
                                             background-position: left;
                                             background-size: contain;
                                        }
                                   }

                                   &.footer__mail {
                                        &:after {
                                             @include sassvg('envelope', $brand-color-white);
                                             background-position: left;
                                             background-size: contain;
                                        }
                                   }
                              }
                         }

                         &:nth-child(3) {
                              .footer__link {
                                   position: relative;

                                   &:after {
                                        position: absolute;
                                        @include size(20px);
                                        content: '';
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                   }

                                   a {
                                        margin-left: 30px;
                                   }

                                   &--facebook {
                                        @include sassvg('facebook', $brand-color-white);
                                        background-position: left;
                                        background-size: contain;
                                   }

                                   &--instagram {
                                        @include sassvg('instagram', $brand-color-white);
                                        background-position: left;
                                        background-size: contain;
                                   }
                              }
                         }

                         li {
                              &:first-child {
                                   text-transform: uppercase;
                                   font-weight: 700;
                                   color: #bc921a;
                                   margin-bottom: 30px;

                                   a {
                                        color: #bc921a;
                                        text-transform: uppercase;
                                        font-weight: 700;
                                        text-decoration: none;
                                   }
                              }

                              &:not(&:first-child) {
                                   min-height: 20px;
                                   margin-bottom: 15px;
                              }
                         }

                         @include from(768px) {
                              &:not(&:last-child) {
                                   margin-bottom: 0;
                              }
                         }
                    }

                    @include from(768px) {
                         display: flex;
                         justify-content: space-between;
                    }
               }
          }

          &:nth-child(2) {
               .footer__row {
                    @include flex(flex, space-between, center);
                    padding-bottom: 5px;

                    p {
                         font-size: 14px;

                         @include from(568px) {
                              //font-size: 12px;
                         }
                    }
               }

               img {
                    @include size(40px, auto);
                    margin-right: 20px;
                    border-radius: 50px;

                    @include from(768px) {
                         margin-right: 0;
                    }
               }
          }
     }

     &__author {
          color: #bc921a;
     }
}
// end:: footer

// begin:: scroll top button
.scroll-top-button {
     @include size(50px);
     position: fixed;
     right: 10px;
     bottom: 70px;
     background-color: $brand-color-1;
     border-radius: 50px;
     transition: 0.25s ease-in-out;
     opacity: 0;
     cursor: pointer;

     &:after {
          @include size(100%);
          @include sassvg('chevron-right', $brand-color-white);
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-90deg);
          background-position: center;
          background-size: 15px;

          @include from(768px) {
               background-size: 20px;
          }
     }

     &.active {
          opacity: 1;
     }

     @include from(768px) {
          @include size(60px);
          right: 20px;
     }

     @include from(1300px) {
          @include size(60px);
          right: 20px;
          bottom: 70px;
     }
}
// end:: scroll top button

.no-webp {
     .social {
          background: radial-gradient(circle, rgba(160, 112, 15, 0.6054796918767507) 0%, rgba(160, 112, 15, 0.6054796918767507) 100%),
               url('../images/social-banner.png') no-repeat;
     }
}
